import { getCookie } from './cookies';
import { v as bvVar } from './vars';

const promises = {};

const requireScript = (src, async = false, { resolveOnError = false } = {}) => {
  promises[src] = promises[src] || new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = async;
    script.onload = () => { resolve(); };
    script.onerror = () => { (resolveOnError ? resolve : reject)(); };
    script.src = src;

    document.getElementsByTagName('head')[0].appendChild(script);
  });

  return promises[src];
};

const requireStyle = (src, async = false) => {
  promises[src] = promises[src] || new Promise((resolve, reject) => {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.async = async;
    link.onload = () => {
      link.rel = 'stylesheet';
      link.onload = null;
      resolve();
    };
    link.onerror = () => { link.onerror = null; reject(); };
    link.href = src;

    if (bvVar('oldBrowser')) {
      link.rel = 'stylesheet';
    } else {
      link.rel = 'preload';
      link.as = 'style';
    }
    link.media = 'all';

    document.getElementsByTagName('head')[0].appendChild(link);
  });

  return promises[src];
};

const requireFile = (src, async = false) => {
  if (!src) {
    return Promise.resolve(true);
  }

  if (typeof src === 'function') {
    return src();
  }

  if (src.match(/\.css$/)) {
    return requireStyle(src, async);
  }

  if (src.match(/\.js$/)) {
    return requireScript(src, async);
  }

  return Promise.resolve(true);
};

// ----------------------------------------------------------------------------------------------
// Adding async option here so apps that consist of only 1 bundle can be loaded asynchronously
// Allowing other scripts to be parsed before even if added after
//
// Could be used even when having dependencies if these dependencies are used just for preloading
// Which are loaded after again via lazy components (and not externals)
//
// In this cases we should probably use preload instead and avoid Promise.all
// All start to download at the same time, the App component is rendered
// and lazy loaded components will come when dependency is ready
// ----------------------------------------------------------------------------------------------
const bootApp = async ({
  app, dependencies, softDependencies = [], async = false, subModule = '',
}) => {
  await Promise.all([
    ...dependencies.map((file) => requireFile(file, async)),
    ...softDependencies.map((file) => requireScript(file, async, { resolveOnError: true })),
  ]);

  const loadedApp = await requireFile(app);

  return subModule ? { default: loadedApp[subModule] } : loadedApp;
};

const currencyPrefix = () => decodeURIComponent(getCookie('currency_prefix'));

// TODO raf cookie name/structure changed, to not lose any raf data in between this process
// we still check for previous cookie, which should be removed after these changes are released
const rafToken = () => {
  const rafTrackingData = JSON.parse(decodeURIComponent(getCookie('raf_tracking_data')));
  return rafTrackingData?.raf?.token || getCookie('raf');
};

export {
  requireScript,
  requireStyle,
  requireFile,
  bootApp,
  currencyPrefix,
  rafToken,
};
